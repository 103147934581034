const users =[{
  _id: 
     "620cf97c842422deb6477852",
  name: "arvin",
  email: "holyps4@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "hay its arvin and i make art",
  url: "arvinkarimi.com",
  total_sold_nft: 10,
  total_sold_price: 145,
  banner: "/img/bg/cover_1.jpg",
  avatar: "/img/avatars/fa-1.jpg",
  nfts_for_sale: 2,
  views: 152
},{
  _id: 
     "620cf97c842422deb6477853",
  name: "aesto_naz",
  email: "aesto_naz@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Nana the code monkey",
  url: "aesto_naz.com",
  total_sold_nft: 15,
  total_sold_price: 200,
  banner: "/img/bg/cover_5.jpg",
  avatar: "/img/avatars/fa-16.jpg",
  nfts_for_sale: 4,
  views: 20157
},{
  _id: 
     "620cf97c842422deb6477854",
  name: "amir",
  email: "amirMohseni@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "hi i'm amir i create the best nfts",
  url: "amirmohseni.com",
  total_sold_nft: 1,
  total_sold_price: 10,
  banner: "/img/bg/c-1.jpg",
  avatar: "/img/avatars/fa-2.jpg",
  nfts_for_sale: 2,
  views: 10
},{
  _id: 
     "620cf97c842422deb6477855",
  name: "mrNobody",
  email: "mrNobody@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "just NoBody!!",
  url: "mrNobody.com",
  total_sold_nft: 5,
  total_sold_price: 20,
  banner: "/img/bg/c-2.jpg",
  avatar: "/img/avatars/fa-9.jpg",
  nfts_for_sale: 3,
  views: 515
},{
  _id: 
     "620cf97c842422deb6477856",
  name: "theOldGuy",
  email: "theOldGuy@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Hi guys you shoud buy my nfts",
  url: "theOldGuy.com",
  total_sold_nft: 1,
  total_sold_price: 3,
  banner: "/img/bg/c-3.jpg",
  avatar: "/img/avatars/fa-15.jpg",
  nfts_for_sale: 2,
  views: 557
},{
  _id: 
     "620cf97c842422deb6477857",
  name: "frenchMan",
  email: "frenchMan@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "frenchMan French NFT",
  url: "frenchMan.com",
  total_sold_nft: 5,
  total_sold_price: 20,
  banner: "/img/bg/c-4.jpg",
  avatar: "/img/avatars/fa-10.jpg",
  nfts_for_sale: 2,
  views: 27
},{
  _id: 
     "620cf97c842422deb6477858",
  name: "redHead",
  email: "redHead235@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "i don't care...",
  url: "redHead.com",
  total_sold_nft: 22,
  total_sold_price: 35,
  banner: "/img/bg/c-5.jpg",
  avatar: "/img/avatars/fa-9.jpg",
  nfts_for_sale: 2,
  views: 657
},{
  _id: 
     "620cf97c842422deb6477859",
  name: "afro",
  email: "afro8@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "afro like afro jack :))",
  url: "afro.com",
  total_sold_nft: 1,
  total_sold_price: 245,
  banner: "/img/bg/c-6.jpg",
  avatar: "/img/avatars/fa-17.jpg",
  nfts_for_sale:2,
  views: 84
},{
  _id: 
     "620cf97c842422deb647785a",
  name: "babyMan",
  email: "babyManBaby@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "i'm a man but also a baby!! what??!!",
  url: "babyMan.com",
  total_sold_nft: 81,
  total_sold_price: 545,
  banner: "/img/bg/c-7.jpg",
  avatar: "/img/avatars/fa-18.jpg",
  nfts_for_sale: 3,
  views: 1354
},{
  _id: 
     "620cf97c842422deb647785b",
  name: "rainbow",
  email: "rainbow7@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "I Use only colors of rainbow in my Art",
  url: "rainbow.com",
  total_sold_nft: 15,
  total_sold_price: 11,
  banner: "/img/bg/c-8.jpg",
  avatar: "/img/avatars/fa-19.jpg",
  nfts_for_sale: 6,
  views: 745
},{
  _id: 
     "620cf97c842422deb647785c",
  name: "jack_the_ripper",
  email: "jack_the_ripper2020@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "jack the ripper the artist",
  url: "jackTheRipperArtist.com",
  total_sold_nft: 8,
  total_sold_price: 7,
  banner: "/img/bg/c-9.jpg",
  avatar: "/img/avatars/fa-12.jpg",
  nfts_for_sale: 2,
  views: 21
},{
  _id: 
     "620cf97c842422deb647785d",
  name: "Benito",
  email: "Benit0@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "welcome guys to my page...",
  url: "Benito00.com",
  total_sold_nft: 3,
  total_sold_price: 1,
  banner: "/img/bg/c-10.jpg",
  avatar: "/img/avatars/fa-14.jpg",
  nfts_for_sale: 3,
  views: 154
},{
  _id: 
     "620cf97c842422deb647785e",
  name: "rindmoney",
  email: "rindmoney@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Thinker.",
  url: "rindmoney.com",
  total_sold_nft: 2,
  total_sold_price: 5,
  banner: "/img/bg/c-11.jpg",
  avatar: "/img/avatars/fa-4.jpg",
  nfts_for_sale: 3,
  views: 310
},{
  _id: 
     "620cf97c842422deb647785f",
  name: "sovietsponge",
  email: "sovietsponge@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Web specialist.",
  url: "sovietsponge.com",
  total_sold_nft: 32,
  total_sold_price: 120,
  banner: "/img/bg/c-12.jpg",
  avatar: "/img/avatars/fa-7.jpg",
  nfts_for_sale: 2,
  views: 57
},{
  _id: 
     "620cf97c842422deb6477860",
  name: "punctualwitch",
  email: "punctualwitch@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Award-winning explorer",
  url: "punctualwitch.com",
  total_sold_nft: 3,
  total_sold_price: 12,
  banner: "/img/bg/c-13.jpg",
  avatar: "/img/avatars/fa-20.jpg",
  nfts_for_sale: 3,
  views: 53
},{
  _id: 
     "620cf97c842422deb6477861",
  name: "artichokestinker",
  email: "artichokestinker@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Extreme internet geek.",
  url: "artichokestinker.com",
  total_sold_nft: 2,
  total_sold_price: 5,
  banner: "/img/bg/c-14.jpg",
  avatar: "/img/avatars/fa-21.jpg",
  nfts_for_sale: 1,
  views: 434
},{
  _id: 
     "620cf97c842422deb6477862",
  name: "jellyfishvolleyball",
  email: "jellyfishvolleyball@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Subtly charming pop culture evangelist.",
  url: "jellyfishvolleyball.com",
  total_sold_nft: 12,
  total_sold_price: 13,
  banner: "/img/bg/c-15.jpg",
  avatar: "/img/avatars/fa-11.jpg",
  nfts_for_sale: 1,
  views: 105
},{
  _id: 
     "620cf97c842422deb6477863",
  name: "inningdepressed",
  email: "inningdepressed@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Freelance food scholar",
  url: "inningdepressed.com",
  total_sold_nft: 5,
  total_sold_price: 13,
  banner: "/img/bg/c-16.jpg",
  avatar: "/img/avatars/fa-13.jpg",
  nfts_for_sale: 2,
  views: 73
},{
  _id: 
     "620cf97c842422deb6477864",
  name: "drawpath",
  email: "drawpath@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Professional twitter junkie.",
  url: "drawpath.com",
  total_sold_nft: 1,
  total_sold_price: 2,
  banner: "/img/bg/c-17.jpg",
  avatar: "/img/avatars/fa-22.jpg",
  nfts_for_sale: 1,
  views: 56
},{
  _id: 
     "620cf97c842422deb6477865",
  name: "rareflakjacket",
  email: "rareflakjacket@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Total social media fanatic.",
  url: "rareflakjacket.com",
  total_sold_nft: 5,
  total_sold_price: 5,
  banner: "/img/bg/c-18.jpg",
  avatar: "/img/avatars/fa-5.jpg",
  nfts_for_sale: 1,
  views: 165
},{
  _id: 
     "620cf97c842422deb6477866",
  name: "mallwithout",
  email: "mallwithout@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Troublemaker.",
  url: "mallwithout.com",
  total_sold_nft: 52,
  total_sold_price: 34,
  banner: "/img/bg/c-19.jpg",
  avatar: "/img/avatars/fa-8.jpg",
  nfts_for_sale: 2,
  views: 668
},{
  _id: 
     "620cf97c842422deb6477867",
  name: "ribbitsowse",
  email: "ribbitsowse@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Proud coffee buff",
  url: "ribbitsowse.com",
  total_sold_nft: 11,
  total_sold_price: 15,
  banner: "/img/bg/c-20.jpg",
  avatar: "/img/avatars/fa-23.jpg",
  nfts_for_sale: 1,
  views: 1355
},{
  _id: 
     "620cf97c842422deb6477868",
  name: "literaturemedal",
  email: "literaturemedal@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Certified social media ninja.",
  url: "literaturemedal.com",
  total_sold_nft: 15,
  total_sold_price: 13,
  banner: "/img/bg/c-21.jpg",
  avatar: "/img/avatars/fa-24.jpg",
  nfts_for_sale: 1,
  views: 43
},{
  _id: 
     "620cf97c842422deb6477869",
  name: "grapnelpome",
  email: "grapnelpome@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Entrepreneur.",
  url: "grapnelpome.com",
  total_sold_nft: 1,
  total_sold_price: 4,
  banner: "/img/bg/c-1.jpg",
  avatar: "/img/avatars/fa-23.jpg",
  nfts_for_sale: 2,
  views: 120
},{
  _id: 
     "620cf97c842422deb647786a",
  name: "devastatedable",
  email: "devastatedable@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Unable to type with boxing gloves on.",
  url: "devastatedable.com",
  total_sold_nft: 5,
  total_sold_price: 8.2,
  banner: "/img/bg/c-2.jpg",
  avatar: "/img/avatars/fa-2.jpg",
  nfts_for_sale: 1,
  views: 1874
},{
  _id: 
     "620cf97c842422deb647786b",
  name: "flashyplop",
  email: "flashyplop@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Extreme writer.",
  url: "flashyplop.com",
  total_sold_nft: 2,
  total_sold_price: 6.4,
  banner: "/img/bg/c-3.jpg",
  avatar: "/img/avatars/fa-1.jpg",
  nfts_for_sale: 1,
  views: 153
},{
  _id: 
     "620cf97c842422deb647786c",
  name: "mournpoverty",
  email: "mournpoverty@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Gamer.",
  url: "mournpoverty.com",
  total_sold_nft: 1,
  total_sold_price: 1.5,
  banner: "/img/bg/c-4.jpg",
  avatar: "/img/avatars/fa-22.jpg",
  nfts_for_sale: 3,
  views: 152
},{
  _id: 
     "620cf97c842422deb647786d",
  name: "kidneysdeft",
  email: "kidneysdeft@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Proud creator.",
  url: "kidneysdeft.com",
  total_sold_nft: 7,
  total_sold_price: 8.83,
  banner: "/img/bg/c-5.jpg",
  avatar: "/img/avatars/fa-21.jpg",
  nfts_for_sale: 1,
  views: 1354
},{
  _id: 
     "620cf97c842422deb647786e",
  name: "containerdominate",
  email: "containerdominate@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Falls down a lot.",
  url: "containerdominate.com",
  total_sold_nft: 8,
  total_sold_price: 12.7,
  banner: "/img/bg/c-6.jpg",
  avatar: "/img/avatars/fa-3.jpg",
  nfts_for_sale: 1,
  views: 32
},{
  _id: 
     "620cf97c842422deb647786f",
  name: "abalonestarling",
  email: "abalonestarling@gmail.com",
  password: "123456789",
  isAdmin: false,
  isCreator: true,
  bio: "Unapologetic travel junkie.",
  url: "abalonestarling.com",
  total_sold_nft: 80,
  total_sold_price: 72.9,
  banner: "/img/bg/c-7.jpg",
  avatar: "/img/avatars/fa-6.jpg",
  nfts_for_sale: 2,
  views: 64
}
// ,{
//   _id: 
//      "620cf97c842422deb6477870",
//   name: "phalanxheartpulse",
//   email: "phalanxheartpulse@gmail.com",
//   password: "123456789",
//   isAdmin: false,
//   isCreator: true,
//   bio: "Lifelong coffee geek.",
//   url: "phalanxheartpulse.com",
//   total_sold_nft: 14,
//   total_sold_price: 17.47,
//   banner: "/img/bg/c-8.jpg",
//   avatar: "/img/avatars/avatar_10.png",
//   nfts_for_sale: 1,
//   views: 75
// }
]
export default users