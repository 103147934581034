import React,{useEffect, useState} from 'react'
import HeroPopularCreatorCart from './HeroPopularCreatorCart'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { listUser } from '../actions/userActions'
const HeroPopularCreators = () => {
	const dispatch = useDispatch()
	const userList = useSelector(state => state.userList)
	const {loading , users} = userList
	const [topUsers , setTopUsers] = useState([])

	useEffect(() => {
		dispatch(listUser())

	}, [dispatch])
	useEffect(() => {
	
		setTopUsers(prev => {
			users.sort(function(a, b){return b.total_sold_price - a.total_sold_price})
			return (
				users.slice(0,4)
			)
		})

}, [users.length])
var x = window.matchMedia("(max-width: 992px)")
const [deviceChange , setDeviceChange] = useState(x.matches)

	useEffect(() => {
		window.addEventListener('resize', () => {

			x = window.matchMedia("(max-width: 992px)")
			if(deviceChange === x.matches){
			
			}else{
				setDeviceChange(prev => x.matches)
				
			}
		}
		);
		
	},[x])
	useEffect(() => {
		if(x.matches){
			const slider = document.querySelector('.creators-hero-padding');
			let isDown = false;
			let startX;
			let scrollLeft;
			
			slider.addEventListener('mousedown', (e) => {
			  isDown = true;
			  slider.classList.add('active');
			  startX = e.pageX - slider.offsetLeft;
			  scrollLeft = slider.scrollLeft;
			});
			slider.addEventListener('mouseleave', () => {
			  isDown = false;
			  slider.classList.remove('active');
			});
			slider.addEventListener('mouseup', () => {
			  isDown = false;
			  slider.classList.remove('active');
			});
			slider.addEventListener('mousemove', (e) => {
			  if(!isDown) return;
			  e.preventDefault();
			  const x = e.pageX - slider.offsetLeft;
			  const walk = (x - startX) * 2; //scroll-fast
			  slider.scrollLeft = scrollLeft - walk;
			  console.log(walk);
			});
		}
		
	} , [x])
    return (
        <div className="container hero-creators-div">
			<div className="back-img-div-left" >
				<img className="back-img" src="/img/bg/Group-132-blur.png" /> 
			</div>
			<div className="back-img-div" >
				<img className="back-img" src="/img/bg/Group-132-blur.png" /> 
			</div>
				<div className="row creators-hero-padding">
					{/* <div className="col-lg-1"></div> */}
					{
						topUsers.map(user => {
							if(deviceChange){
								return (
									
									<div className=" col-6 col-md-4 col-sm-4">
										<HeroPopularCreatorCart user={user}/>
									</div>
								)
							}else{
								return (
									<div className="col-lg-3 col-md-4 col-3">
										<HeroPopularCreatorCart user={user}/>
									</div>
								)
							}
							
						})
					}
					{/* <div className="col-lg-1"></div> */}

				</div>
				{
					deviceChange ? <div className="mt-50 m-top-52  btn-div" style={{textAlign:"center"}}>
						<div className="my-btns-div">

					<Link className="btn btn-grad btn-dark btn-hover-tablet" to="/register" ></Link>
					<p className="my-btns-text">Be a creator</p>
					</div>
				</div>
				 :
					(
						<div className="mt-60 m-top-52  btn-div" style={{textAlign:"center"}}>	
								<div className="my-btns-div">

									<Link className="btn btn-grad btn-dark btn-hover" to="/register" ></Link>
									<p className="my-btns-text">Be a creator</p>
									</div>
								</div>
					)
				}
								
			</div>
    )
}

export default HeroPopularCreators
