import React from 'react'
import { Link } from 'react-router-dom'

const MarketData = () => {
    return (
        <div className="container market-data-page" style={{position:"relative"}}>
			<div className="back-img-div-left" style={{top: "-100%"}}>
				<img className="back-img" src="/img/bg/Group-132-blur.png" /> 
			</div>
			<div className="back-img-div" style={{top: "-100%"}}>
				<img className="back-img" src="/img/bg/Group-132-blur.png" /> 
			</div>
			    <div className="logos__wrap pull-mt market-div" style={{backgroundColor: "transparent" , border: "none" , boxShadow: "none"}}>
			        <div className="row align-items-center   justify-content-center">
			            <div className="col-lg-8 market-data-div">
			                <h3 className="section__title market-data-text md:mb-20">Pull market data from our
			                    digital asset API</h3>
			            </div>
			            <div className="connect-wallet-btn-div">
						<div className="my-btns-div">

			                <Link className="btn btn-grad btn-hover" to="/connectwallet"></Link>
							<p className="my-btns-text">Connect wallet</p>
							</div>
			            </div>
			        </div>
			    </div>
			</div>
    )
}

export default MarketData
