import React,{useEffect , useRef, useState} from 'react'
import GlobalCommunity from '../components/GlobalCommunity'
import Hero from '../components/Hero'
import MarketData from '../components/MarketData'
import PopularCreators from '../components/PopularCreators'
import TodaysPick from '../components/TodaysPick'
import TopGallery from '../components/TopGallery'
import PlaceBidPopup from '../components/PlaceBidPopup'
import BidSuccessPopup from '../components/BidSuccessPopup'
import useLoco from '../hooks/useLoco'
import Footer from '../components/Footer'
// import LocomotiveScroll from "locomotive-scroll";
import "../locomotive-scroll.css";
const Home = () => {
	var x = window.matchMedia("(max-width: 992px)")
    useEffect(() => {
		window.addEventListener('resize', () => {
			x = window.matchMedia("(max-width: 992px)")
		
		}
		);
		
	},[x])
     useLoco(x.matches)


    // useEffect(() => {
    //     const scroll = new LocomotiveScroll({
    //       el: document.querySelector("#viewport"),
    //       smooth: true,
    //       smoothMobile: false,
    //       resetNativeScroll: true
    //     });
    //     return () => {
    //       if (scroll) scroll.destroy();
    //   }
    // })
    return (
        // <div >
        <div id="viewport" data-scroll-container>
        <div   className="home-page-loading"  data-scroll-section>
            <div >
        <BidSuccessPopup />
		<PlaceBidPopup />
        <Hero />
        <PopularCreators show={true}/>
        <TodaysPick />
        <TopGallery />
        {/* <GlobalCommunity /> */}
        <MarketData/>
        <Footer />

        </div>
        </div>
        </div>
    )
}

export default Home
